import React, { useContext } from 'react';
import LoginLandingEnugu from '../Components/LoginLandingEnugu';
import LoginLandingCustom from '../Components/LoginLandingCustom.jsx';
import LoginLanding from '../Components/LoginLanding';
import { DataContext } from '../Context/dataContext';
import educatorImg from '../images/educatorImg.png';
import { ForgotPswrdModal } from './ForgotPassword';
import { FetchContext } from '../Context/fetchContext';
import anambraFrame from '../images/anambra-frame.png'
import enuguFrame from '../images/enugu-frame.png'

const landingPageConfig = {
    'enugustate.edves.net': {
        HeadingText: 'Welcome to Enugu State Ministry of Education Portal',
        subHeadingText: 'At the forefront of our mission is the integration of Artificial Intelligence (AI) into the educational landscape. Governor Ndubuisi Peter Mbah envisions a future where AI technologies play a pivotal role in fostering personalized learning experiences, optimizing educational resources, and preparing students for the demands of a rapidly evolving digital world',
        subHeadingHighlight: '',
        person: 'school staff',
        loginBar1: 'Log in as a School Staff',
        loginBar2: 'Log in as a Parent',
        loginBar3: 'Log in as a Student',
        frameImage: enuguFrame,
    },

    'anambra.edves.net': {
        HeadingText: 'Welcome to Anambra State Ministry of Education Portal',
        subHeadingText: 'At the forefront of our mission is the integration of Artificial Intelligence (AI) into the educational landscape. Governor Charles Soludo envisions a future where AI technologies play a pivotal role in fostering personalized learning experiences, optimizing educational resources, and preparing students for the demands of a rapidly evolving digital world',
        subHeadingHighlight: '',
        person: 'school staff',
        loginBar1: 'Log in as a School Staff',
        loginBar2: 'Log in as a Parent',
        loginBar3: 'Log in as a Student',
        frameImage: anambraFrame,
        bgImage: 'bg-anambra.jpg'
    }
};

function DynamicLandingPage({ host }) {

    // if (host == 'enugustate.edves.net') {
    //     return (
    //         <LoginLandingEnugu
    //             HeadingText = 'Welcome to Enugu State Ministry of Education Portal'
    //             subHeadingText = 'At the forefront of our mission is the integration of Artificial Intelligence (AI) into the educational landscape. Governor Ndubuisi Peter Mbah envisions a future where AI technologies play a pivotal role in fostering personalized learning experiences, optimizing educational resources, and preparing students for the demands of a rapidly evolving digital world'
    //             subHeadingHighlight = ''
    //             img = {educatorImg}
    //             person = 'school staff'
    //             loginBar1 = 'Log in as a School Staff'
    //             loginBar2 = 'Log in as a Parent'
    //             loginBar3 = 'Log in as a Student'
    //         />
    //     );
    // }

    if (undefined !== landingPageConfig[host]) {
        return (
            <LoginLandingCustom
                img = {educatorImg}
                frameImage = {landingPageConfig[host]?.frameImage ?? educatorImg}
                {...landingPageConfig[host]}
            />
        );
    }

    return (
        <LoginLanding
            HeadingText = 'Easily manage all your school processes and activities'
            subHeadingText = 'can use Edves to manage all activities from enrollment to attendance, calendar, timetable, growth tracking, student records, scheduling tests/exams, sharing assignments, class notes...'
            subHeadingHighlight = 'School Staff'
            img = {educatorImg}
            person = 'school staff'
            loginBar1 = 'Log in as a School Staff'
            loginBar2 = 'Log in as a Parent'
            loginBar3 = 'Log in as a Student'
        />
    );

};

const EducatorLogin = () => {
    const dataContext = useContext(DataContext)
    const fetchContext = useContext(FetchContext);

    const host = fetchContext.host;

    // console.log(dataContext.fPswrdComponent)
    return (
        <>
            { (dataContext.fPswrdComponent === 'school staff' || dataContext.fPswrdComponent === '')  && <ForgotPswrdModal />}
            {/* {host !== 'enugustate.edves.net' ? <LoginLanding
                HeadingText = 'Easily manage all your school processes and activities'
                subHeadingText = 'can use Edves to manage all activities from enrollment to attendance, calendar, timetable, growth tracking, student records, scheduling tests/exams, sharing assignments, class notes...'
                subHeadingHighlight = 'School Staff'
                img = {educatorImg}
                person = 'school staff'
                loginBar1 = 'Log in as a School Staff'
                loginBar2 = 'Log in as a Parent'
                loginBar3 = 'Log in as a Student'
            />: 
            <LoginLandingEnugu
                HeadingText = 'Welcome to Enugu State Ministry of Education Portal'
                subHeadingText = 'At the forefront of our mission is the integration of Artificial Intelligence (AI) into the educational landscape. Governor Ndubuisi Peter Mbah envisions a future where AI technologies play a pivotal role in fostering personalized learning experiences, optimizing educational resources, and preparing students for the demands of a rapidly evolving digital world'
                subHeadingHighlight = ''
                img = {educatorImg}
                person = 'school staff'
                loginBar1 = 'Log in as a School Staff'
                loginBar2 = 'Log in as a Parent'
                loginBar3 = 'Log in as a Student'
            />
            } */}
            <DynamicLandingPage host={host} />
            
        </>
    )
}

export default EducatorLogin;