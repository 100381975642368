import React, { useContext, useEffect, useState } from 'react';
import ImageSection from './common/ImageSectionCustom.jsx';
import LoginInputs from './LoginInputs';
import { motion } from 'framer-motion';
import { FetchContext } from '../Context/fetchContext';
import LoadingModal from './LoadingModal';
import ErrorModal from './ErrorModal';
import { toTitleCase } from '../Utils/titleCase';
import sanwoOlu from '../images/sanwoOlu.jpg';
import WhiteEdvesLogo from '../SVGs/WhiteEdvesLogo';

import bg from '../images/bg.png';

import BrowserDetector from '../Utils/browserDetector';

const LoginLandingCustom = ({
  HeadingText,
  subHeadingText,
  subHeadingHighlight,
  img,
  bgImage,
  frameImage,
  loginBar1,
  loginBar2,
  loginBar3,
  person,
}) => {
  bgImage = bgImage ?? 'bg-custom-default.jpg';
  const fetchContext = useContext(FetchContext);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [schData, setSchData] = useState(null);

  const host = fetchContext.host;

  const imageSectionVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
    transition: { duration: 1 },
  };

  useEffect(() => {
    const getSchData = async () => {
      try {
        setLoading(true);
        const { data } = await fetchContext.getRoute(`getsubschools/${host}`);
        //   console.log(data);
        if (data.status) {
          setLoading(false);
          setSchData(data.data.schoolDetails);
          localStorage.setItem('schoolData', JSON.stringify(data.data));
          // const defaultSchoolDataLS = JSON.parse(localStorage.getItem('schoolData'))
          // console.log(data.data.schoolDetails.defaultSchoolName)
          document.title = `Edves - ${toTitleCase(
            data.data.schoolDetails.defaultSchoolName
          )}`;
        } else {
          setLoading(false);
          setError(data.message);
        }
      } catch (error) {
        setLoading(false);
        setError(
          'Something went wrong, try again later or contact school admin'
        );
      }
    };

    getSchData();

    if (!new BrowserDetector().isSupported()) {
      setError('Your browser seems to be outdated, please update your browser');
    } else {
      return;
    }

    return () => {};
  }, [fetchContext, host]);

  const continueToPortal = () => {
    setError('');
  };

  // window.addEventListener('offline', function(e) { console.log('offline'); setError('Appears you are offline. Check internet connectivity') });
  return (
    <>
      {loading && <LoadingModal />}
      {error && (
        <ErrorModal
          errMsg={error}
          errorType='browser'
          continueToPortal={continueToPortal}
        />
      )}

      <div
        className={`flex w-full p-5 sm:p-10 bg sm:h-screen xs:p-10 xs:h-screen`}
        style={!bgImage ? {} : {backgroundImage: `url(/login/images/bg/${bgImage})`}}
      >



        <div className='hidden lg:block relative w-full h-full'>
          <motion.div
            variants={imageSectionVariants}
            animate='visible'
            initial='hidden'
            exit='exit'
            className='fixed w-45'
          >
            <ImageSection
              headingText={HeadingText}
              subHeadingText={subHeadingText}
              subHeadingHighlight={subHeadingHighlight}
              img={img}
              frameImage={frameImage ?? null}
            />
          </motion.div>
        </div>
        <div className='w-full h-full hidden'>
          {/* <img src={img} alt="relevantImg" className="object-cover bg-fixed w-full h-screen" /> */}
          {host !== 'lasgeduhub.edves.net' ? (
            <span className='flex-shrink w-full'>
              <img
                src={img}
                alt='relevantPic'
                className='absolute inset-0 object-cover bg-clip-content w-full h-full'
              />
            </span>
          ) : (
            <span className='flex-shrink w-full '>
              <img
                src={sanwoOlu}
                alt='relevantPic'
                className='absolute object-contain inset-0 w-full h-full'
              />
              <div className='inset-0 absolute w-full h-full bg-primary bg-opacity-70'></div>
              <div className='inset-0 absolute w-full h-full bg-headingDark bg-opacity-40'></div>
            </span>
          )}
        </div>
        <div className='inset-0 overflow-x-hidden lg:pt-8 px-4 lg:px-12 w-full w-85  lg:top-10 left-auto lg:right-0 bg-white rounded-lg'>
          <div className='w-full lg:w-full mx-auto h-full'>
            <LoginInputs
              loginBar1={loginBar1}
              loginBar2={loginBar2}
              loginBar3={loginBar3}
              educator
              host={host}
              person={person}
              schLogo={schData && schData.defaultSchoolloogo}
              schName={schData && schData.defaultSchoolName}
              isCustomPortal={true}
              isEnuguPortal={host === "enugustate.edves.net"}
            />
          </div>
          <div className='absolute bottom-5 right-auto left-auto text-primarry text-p font-bold sm:hidden'> 
                <div className='flex space-x-1'>
                    <span> Powered by </span >
                    <span  className='mt-1'> <WhiteEdvesLogo />  </span>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default LoginLandingCustom;
