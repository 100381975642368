import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { replaceChar } from '../Utils/ReplaceChar';
// import { toTitleCase } from '../Utils/titleCase';

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
    const [ currPortalReqBody, setCurrPortalReqBody ] = useState(null);
    const token = localStorage.getItem('token')
    
    axios.defaults.baseURL = "https://devapi.edves.net/edvesapi/public/v1"
    if (token) axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};

    // const defaultSchoolDataLS = JSON.parse(localStorage.getItem('schoolData'))
    // console.log(defaultSchoolDataLS && defaultSchoolDataLS.schoolDetails.defaultSchoolName)
    // document.title = (`Edves - ${toTitleCase(defaultSchoolDataLS && defaultSchoolDataLS.schoolDetails.defaultSchoolName)}`);
    
    axios.interceptors.request.use(request => {
      // console.log(request);
      // Edit request config
      return request;
    }, error => {
      // console.log(error);
      return Promise.reject(error);
    });
    
    let host = window.location.host.includes('localhost') ? 'demo.edves.net' : window.location.host;

    // // TO DO: Remove this line below
    // host = 'anambra.edves.net';

    if (window.location.host.includes('enems.enugustate.gov.ng')) {
      host = 'enugustate.edves.net';
    }
    // enugustate.edves.net
    const currPortal = window.location.pathname.split('/')[2];
    useEffect(() => {
      const currPortalReqBody = replaceChar(currPortal && currPortal, currPortal && currPortal.lastIndexOf('s'), '');
      setCurrPortalReqBody(currPortalReqBody)
      // console.log(currPortalReqBody)
      return () => {
      }
    }, [currPortal]);

    const post = (route, body) => axios.post(route, body);

    const get = (route) => axios.get(route)

//   const authAxios = axios.create({
//     baseURL: process.env.REACT_APP_API_URL
//   });

//   useEffect(() => {
//     const getCsrfToken = async () => {
//       const { data } = await authAxios.get('api/csrf-token');
//       console.log(data);
//       authAxios.defaults.headers['X-CSRF-Token'] = data.csrfToken
//     };

//     getCsrfToken();
//   }, [])

  // Only with localStorage
  // authAxios.interceptors.request.use(
  //   config => {
  //     config.headers.Authorization = `Bearer ${authContext.authState.token}`;
  //     return config
  //   },
  //   error => {
  //     return Promise.reject(error);
  //   }
  // )

  return (
    <Provider
      value={{
        axios,
        host,
        currPortal,
        currPortalReqBody,
        getRoute: (route) => get(route),
        postRoute: (route, body) => post(route, body)
      }}
    >
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };
